<template>
  <navbar>
    <template v-slot:navbarTitle>製品検査</template>
  </navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0">
        <i class="bi bi-clipboard-data me-1"></i>中断中の検査一覧
      </h5>
    </div>

    <div class="d-flex justify-content-end gap-2 mb-2">
      <pagination :prev="prev" :next="next" @move-page="movePage"></pagination>
    </div>

    <!-- results -->
    <div v-if="results && results.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-2">検査番号</th>
              <th scope="col" class="col-sm-1">検査種別</th>
              <th scope="col" class="col-sm-1">型式</th>
              <th scope="col" class="col-sm-1">機種コード</th>
              <th scope="col" class="col-sm-1">客先製品コード</th>
              <th scope="col" class="col-sm-3">検査シート</th>
              <th scope="col" class="col-sm-1">検査員</th>
              <th scope="col" class="col-sm-2">検査開始日時</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="result in results" :key="result.id">
              <td scope="row" class="text-center">
                <router-link
                  :to="{
                    name: 'ManageInspectionResultInterruptedDetail',
                    params: { id: result.id },
                  }"
                  >{{ result.inspection_result_number }}</router-link
                >
              </td>
              <td class="text-center">{{ result.inspection_type_name }}</td>
              <td>{{ result.product_model }}</td>
              <td class="text-center">{{ result.product_code }}</td>
              <td>{{ result.client_product_code }}</td>
              <td>{{ result.inspection_name }}</td>
              <td class="text-center">{{ result.worker }}</td>
              <td class="text-end">{{ result.begin_datetime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>
  </main>
</template>

<script>
import Pagination from '@/components/AppNoCountPagination.vue';
import Navbar from '@/components/Navbar.vue';
import store from '../store';

export default {
  name: 'ManageInspectionResultInterrupted',
  inject: ['dayjs'],
  components: {
    Navbar,
    Pagination,
  },

  data() {
    return {}
  },

  computed: {
    results() {
      return store.getters['inspection_result_interrupted/results']
    },
    prev() {
      return store.getters['inspection_result_interrupted/prev']
    },
    next() {
      return store.getters['inspection_result_interrupted/next']
    },
  },

  async created() {
    await this.getData()
  },
  beforeRouteLeave(to, from, next) {
    // 詳細画面以外のページに移動する場合にデータをクリアする
    if (to.name !== 'ManageInspectionResultInterruptedDetail') {
      this.clearData()
    }
    next()
  },

  methods: {
    getData() {
      return store.dispatch('inspection_result_interrupted/fetchAll')
    },
    clearData() {
      store.commit('inspection_result_interrupted/clearData')
    },
  },
}
</script>
